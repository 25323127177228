@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.listContainer {
  position: absolute;
  z-index: 999;
  width: 100%;
  display: block;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  padding: .2rem 1.2rem;

  .searchingLabel {
    font-weight: bold;
    padding: 10px 16px;
  }

  .listItem {
    display: block;
    padding: 1rem 0;
    font-family: 'Ubuntu', sans-serif;
    font-size: remSize(12px);
    color: $text-color;
    border-bottom: 1px dashed lightgrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      color: var(--primary);
    }
  }
}
