.loading-screen-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;

  div {
    position: absolute;
    top: 25%;
    width: 100%;
    height: 100px;
    margin-top: -50px;
    line-height: 50px;
    text-align: center;
    font-size: 2em;
    color: var(--primary);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }

  .fa,
  .fas {
    color: var(--secondary);
  }
}
