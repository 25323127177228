@import "variables";
@import "functions";

$input-padding-y:                       remSize(8px);
$input-padding-x:                       remSize(10px);
$input-border-radius:                   5px;
$input-border-radius-lg:                5px;
$input-border-radius-sm:                5px;
$input-disabled-bg:                     #EEEEEE;
$input-font-family:                     'Lato', sans-serif;
$input-color:                           $text-color;
$input-border-color:                    #BBBBBB;
$input-font-size:                       remSize(14px);
$light:                                 lighten($text-color, 25%);
$dark:                                  darken($text-color, 15%);
