@import '../../../../assets/sass/variables';
@import '../../../../assets/sass/functions';

.profileMenuIcon {
  color: white;

  &:hover {
    text-decoration: none;
    color: darken(white, 5%);
  }
}

.dropdownContainer {
  background: white;
}
