@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.termItem {
  box-sizing: border-box;
  min-width: 26px;
  height: 26px;
  line-height: 22px;
  text-align: center;
  padding: 0px 5px;
  border-radius: 13px;
  border: 2px solid $primary;
  color: $primary;
  font-size: remSize(12px);
  font-weight: bold;
  cursor: pointer;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &.selected {
    background-color: $primary;
    color: white;
  }
}
