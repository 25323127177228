@import "../../../assets/sass/variables";
@import "../../../assets/sass/functions";

.container {
  &.bottom,
  &.bottom-start,
  &.bottom-end {
    padding-top: 20px;
  }

  &.top,
  &.top-start,
  &.top-end {
    padding-bottom: 20px;
  }

  &.left,
  &.left-start,
  &.left-end {
    padding-right: 20px;
  }

  &.right,
  &.right-start,
  &.right-end {
    padding-left: 20px;
  }

  &.small {
    &.bottom,
    &.bottom-start,
    &.bottom-end {
      padding-top: 9px;
    }

    &.top,
    &.top-start,
    &.top-end {
      padding-bottom: 9px;
    }

    &.left,
    &.left-start,
    &.left-end {
      padding-right: 9px;
    }

    &.right,
    &.right-start,
    &.right-end {
      padding-left: 9px;
    }
  }
}

.card {
  padding: 18px;
  background: white;
  border-radius: 5px;
  color: $text-color;
  box-shadow: 0 3px 10px rgba(0,0,0,.1);

  &.secondary{
    background-color: var(--secondary);
    color: white;
  }
  &.primary{
    background-color: var(--primary);
    color: white;
  }
  &.small{
    padding: 5px 10px;
    font-size: remSize(10px);
  }
}
.arrowUp{
  position: absolute;
  width: 0;
  height: 0;
  top: 5px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  &.small{
    border-left-width: 7px;
    border-right-width: 7px;
    border-bottom-width: 7px;
  }
  &.secondary{
    border-bottom-color: var(--secondary);
  }
  &.primary{
    border-bottom-color: var(--primary);
  }
}
.arrowDown{
  position: absolute;
  width: 0;
  height: 0;
  bottom: 5px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid white;
  &.small{
    border-left-width: 7px;
    border-right-width: 7px;
    border-top-width: 7px;
  }
  &.secondary{
    border-top-color: var(--secondary);
  }
  &.primary{
    border-top-color: var(--primary);
  }
}
.arrowLeft{
  position: absolute;
  width: 0;
  height: 0;
  left: 5px;
  border-bottom: 15px solid transparent;
  border-top: 15px solid transparent;
  border-right: 15px solid white;
  &.small{
    border-bottom-width: 7px;
    border-top-width: 7px;
    border-right-width: 7px;
  }
  &.secondary{
    border-right-color: var(--secondary);
  }
  &.primary{
    border-right-color: var(--primary);
  }
}
.arrowRight{
  position: absolute;
  width: 0;
  height: 0;
  right: 5px;
  border-bottom: 15px solid transparent;
  border-top: 15px solid transparent;
  border-left: 15px solid white;
  &.small{
    border-bottom-width: 7px;
    border-top-width: 7px;
    border-left-width: 7px;
  }
  &.secondary{
    border-left-color: var(--secondary);
  }
  &.primary{
    border-left-color: var(--primary);
  }
}
