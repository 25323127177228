@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.header-container {
  position: relative;
  padding: 5px 50px;
  font-size: remSize(24px);
  background: linear-gradient(to right, var(--primary), var(--secondary));
  color: white;

  &>div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    height: 120px;
  }

  div.effect {
    display: inline-block;
    padding: 5px 10px;
    background: white;
    color: cornflowerblue;
    border-radius: 50%;
    font-weight: 600;
  }
}
