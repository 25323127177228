@import 'assets/sass/variables.scss';
@import 'assets/sass/mixins.scss';
@import 'assets/sass/bootstrap-variables-overrides.scss';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'assets/sass/bootstrap-customizations.scss';

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: $base-font-size;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  color: $text-color;
  background: #F9F9F9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

div#root {
  position: relative;
  height: 100%;
}

.app {
  min-height: 100%;
  min-width: 375px;
  margin: 0 auto;

  .main-content-container {
    margin: 0 auto;
    max-width: 1200px;
  }
}

.fa-stack {
  font-size: 0.5em;
}
i.fa,
i.fas {
  vertical-align: middle;
}

#modal-area {
  height: 0px;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.text-default {
  color: $text-color;
}

.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}

@include generateFontSizes($font-sizes, $grid-breakpoints);
@include generateLineHeights($font-sizes, $grid-breakpoints);
