@import '../../../../assets/sass/variables';

.weekView {
  display: flex;
  text-align: center;
  height: 300px;

  .dayColumn {
    width: (100% / 7);
    border-right: 1px solid #ddd;

    &:last-child {
      border-right: 0;
    }

    .dayHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border-bottom: 4px solid $secondary;
      font-size: remSize(10px);
      font-weight: 700;

      padding: 5px;

      .today {
        color: var(--primary);
        border-bottom: 2px solid var(--primary);
      }
    }

    .dayEvents {
      padding: 5px;
    }
  }
}
