@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.pillContainer {
  width: auto;
  border-radius: 18px;
  padding: 6px 16px;
  background: var(--primary);
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  font-size: remSize(12px);
  height: remSize(32px);
  white-space: nowrap;
  cursor: default;

  &.dismissible {
    padding-right: 15px;
  }

  &.secondary {
    background-color: var(--secondary);
  }
}

.dismissBtn {
  cursor: pointer;
  margin-left: 10px;
  color: white;

  &:hover {
    color: darken(white, 2%);
  }
}
