.dashboardButton {
  width: 100%;
  background: transparent;
  border: 5px solid white;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  color: var(--primary);
  transition: background-color 0.2s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);

  &.secondary {
    color: var(--secondary);
  }

  &:hover {
    background-color: rgba(0,0,0,.03);
  }
  &:focus,
  &:active {
    background-color: rgba(0,0,0,.05);
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.06);
    outline: none;
  }
}
