@import '../../../assets/sass/_variables.scss';

.sectionIntro {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-items: center;
  text-transform: uppercase;
  color: var(--primary);
  margin-bottom: 1rem;
  letter-spacing: 0.26em;

  .dottedContainer {
    display: block;
    height: 0;
    border-bottom: 2px dotted var(--primary);
    flex-grow: 1;
    margin-left: 1rem;
  }

  &:not(:first-child) {
    margin-top: 2rem;
  }
}
