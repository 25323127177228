// bootstrap overrides
$primary: #274287;
$secondary: #f08b64;

$text-color: darken(#888888, 3%);
$base-font-size: 14px;
$base-font-family: 'Ubuntu', sans-serif;
$base-font-weight: 400;
$grid-gutter-width: 15px;

$font-sizes: 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 54, 60;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
