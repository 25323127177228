@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.mainContainer {
  z-index: 2;
  position: relative;

  .tabsContainer {
    z-index: 1;
    a {
      position: relative;
      height: 32px;
      line-height: 28px;
      min-width: 120px;
      text-align: center;
      display: block;
      background: transparent;
      padding: 0 1rem;
      margin-right: 0.5rem;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: 1px solid var(--secondary);
      border-bottom: none;
      color: var(--secondary);
      font-family: Ubuntu, sans-serif;
      font-weight: bold;
      font-size: remSize(12px);

      &.active {
        margin-top: 0px;
        color: white;
        background: var(--secondary);

        &:hover{
          text-decoration: none;
        }

        &:after {
          z-index: 3;
          position: absolute;
          display: block;
          content: '';
          left: 50%;
          margin-left: -11px;
          right: auto;
          bottom: -6px;
          width: 0;
          height: 0;
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          border-top: 11px solid var(--secondary);
        }
      }

      &:not(.active):hover {
        background: darken(white, 6%);
        text-decoration: none;
      }
    }
  }

  .body {
    position: relative;
    z-index: 2;
    box-shadow: 0px 2px 2px rgba(0, 0, 00, 0.06);
    background: white;
    padding: remSize(30px);
    border-radius: 5px;
    margin-top: -4px;
  }
}
