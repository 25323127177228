.userAvatar {
  .avatarContainer {
    border-radius: 50%;
    overflow: hidden;
  }
  .defaultAvatarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C4C4C4;
    border-radius: 50%;
    overflow: hidden;
  }
}
