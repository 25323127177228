.filterContainer {
  .dropdown {
  }
  .filterButtonContainer {
    border-right: 1px solid darken(white, 7%);
  }
  .filterItem {
    padding: 0.5rem 0.8rem;
  }
}
