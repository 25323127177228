@import "../../../assets/sass/variables";

.pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
    color: $text-color !important;
    font-weight: 400;
  }
}
