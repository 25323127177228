@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/functions";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarContainer {
    width: 160px;
    border: 1px solid #EEEEEE;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    margin-bottom: 16px;
  }

  .name {
    color: var(--secondary);
    font-family: 'Ubuntu', sans-serif;
    font-size: remSize(14px);
    font-weight: bold;
    margin-top: 16px;
  }

  .logoutBtn {
    border: 2px solid var(--secondary);
    border-radius: 50%;
    color: var(--secondary);
    width: 46px;
    height: 46px;
    padding: 0;
    margin: 0;
    text-align: center;
    background: white;
    &:focus{
      outline: none;
    }
    &:active {
      box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
    }
  }

  .logoutLabel{
    font-family: 'Ubuntu', sans-serif;
    font-size: remSize(12px);
    font-weight: bold;
  }
}
