.toast {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);

  display: flex;
  width: 95vw;
  max-width: 450px;
  align-items: center;
  background-color: var(--primary);
  color: white;

  div {
    text-align: center;
    flex-grow: 1;
  }

  a {
    margin-left: 10px;
    color: white;
    &:hover {
      color: darken(white, 5%);
    }
  }
}
