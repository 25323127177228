@import '../../../assets/sass/functions';

.footer {
  padding: remSize(16px) 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
  color: #C4C4C4;
  font-size: remSize(12px);
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}
