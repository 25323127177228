@import "./functions";

@mixin generateFontSizes($font-sizes, $grid-breakpoints) {
  .kkk{
    font-size: 500px;
  }
  @each $size in $font-sizes {
    .text-#{$size} {
      font-size: remSize($size * 1px);
    }

    @each $bp, $width in $grid-breakpoints {
      @media (min-width: $width) {
        .text-#{$bp}-#{$size} {
          font-size: remSize($size * 1px) !important;
        }
      }
    }
  }
}

@mixin generateLineHeights($line-heights, $grid-breakpoints) {
  @each $size in $line-heights {
    .lh-#{$size} {
      line-height: remSize($size * 1px);
    }

    @each $bp, $width in $grid-breakpoints {
      @media (min-width: $width) {
        .lh-#{$bp}-#{$size} {
          line-height: remSize($size * 1px) !important;
        }
      }
    }
  }
}
