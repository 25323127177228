.calendarContainer {

  .header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .leftContent {
      position: relative;
      display: flex;
      align-items: center;
    }

    .monthNav {
      display: flex;
      align-items: center;
      cursor: pointer;

      .title {
        font-weight: 700;
        font-size: 16px;
      }

      .arrowContainer {
        padding: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }

    .viewModeSelector {
      label {
        cursor: pointer;
      }
    }
  }
}
