@import '../../../../assets/sass/functions';
@import '../../../../assets/sass/variables';

.selectorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 15px;
  font-size: remSize(14px);
  border-radius: 6px;

  .unityLabel {
    font-size: remSize(9px);
    letter-spacing: 0.26em;
  }
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  height: remSize(32px);
  background: white;
  border-radius: 5px;
  padding: 0;
  min-width: remSize(230px);

  button {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    height: 100%;
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 100%;
    text-align: center;
    color: $text-color;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: remSize(12px);
    padding: 0 1rem;

    span {
      flex-grow: 1;
    }

    &:focus {
      outline: none;
    }
  }

  .iconContainer {
    font-size: remSize(20px);
    width: remSize(42px);
    height: 100%;
    color: $text-color;
    text-align: center;
    background: #E5E5E5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .list {
    position: absolute;
    width: remSize(210px);
    top: calc(100% + 5px);
    padding: 1.5rem 1.5rem 1rem 2rem;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 150ms ease-in;
    z-index: 100;
    background: white;
    color: $text-color;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    overflow: hidden;
    font-family: 'Ubuntu', sans-serif;
    font-size: remSize(10px);

    &.expanded {
      visibility: visible;
      opacity: 1;
    }
  }

  .unityEntry {
    display: block;
    padding: 1rem 0;
    font-size: remSize(12px);
    font-weight: bold;
    color: $text-color;
    border-bottom: 1px dashed lightgrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
