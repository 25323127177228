@import '../../../assets/sass/variables';
@import '../../../assets/sass/functions';

.versionGuard {
  text-align: center;
  background: #2c8fc9;
  font-weight: bold;
  font-size: remSize(14px);
  line-height: remSize(42px);
  vertical-align: middle;
  color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);

  .link {
    display: inline-block;
    background: var(--primary);
    border-radius: 4px;
    color: white;
    margin-left: 1rem;
    font-weight: bold;
    font-size: remSize(12px);
    line-height: remSize(27px);
    padding: 0 0.6rem;

    &:hover {
      text-decoration: none;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    }

    &:active {
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.85);
    }
  }
}
