@import '../../../assets/sass/functions';

.breadcrumbs {
  font-size: remSize(12px);
  display: flex;
  margin-bottom: 1rem;

  .breadcrumbsItem {
    display: flex;
    align-items: center;

    > * {
      color: var(--secondary);
    }

    .itemLink {
      margin: 0 1rem;
    }

    &:first-child .itemLink {
      margin: 0 1rem 0 0;
    }

    &:last-child .itemLink {
      font-weight: bold;
    }
  }
}
