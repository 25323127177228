@import '../../../assets/sass/variables';

.mainBtn {
  display: inline-block;
  position: relative;
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  text-align: center;
  padding: 1px 3px;

  &:focus {
    outline: none;
  }
}

.menuContainer {
  position: absolute;
  display: none;
  min-width: 150px;
  z-index: 10;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 12px;

  &.isVisible {
    display: block;
  }

  &.topLeft {
    bottom: calc(100% + 5px);
    right: 5px;
  }

  &.topRight {
    bottom: calc(100% + 5px);
    left: 5px;
  }

  &.bottomLeft {
    top: calc(100% + 5px);
    right: 5px;
  }

  &.bottomRight {
    top: calc(100% + 5px);
    left: 5px;
  }

  .contextMenuItem > * {
    display: block;
    width: 100%;
    background: white;
    border: none;
    padding: 10px;
    text-align: left;
    color: $text-color;
    font-weight: bold;
    border-bottom: 1px solid darken(white, 4%);

    &:hover {
      color: white;
      background: var(--primary);
      text-decoration: none;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }
}
