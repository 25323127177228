.color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch {
  position: relative;
  width: 100%;
  display: block;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  max-width: 100%;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
