.container {
  display: flex;
  padding: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: bold;
  }
}
