.side-menu-container {
  //position: absolute;
  //left: 0;
  background: white;
  width: 85px;
  box-shadow: 0px 2px 2px rgba(0, 0, 00, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 5px;
  font-family: 'Roboto', sans-serif;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      text-align: center;
      padding: 0px;

      a {
        display: block;
        position: relative;
        color: #666;
        font-size: 0.875em;
        font-weight: 500;
        padding: 5px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);

        div {
          margin: 0 auto;
          font-size: 1.3em;
          width: 35px;
          height: 35px;
          line-height: 35px;
          color: var(--primary);
          border-radius: 50%;
        }
      }

      a:hover,
      a.active {
        background: rgba(0, 0, 0, 0.03);
        text-decoration: none;
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
        color: #666;

        div {
          background: var(--primary);
          color: white;
        }

        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 100%;
          background: var(--primary);
          top: 0;
          left: 0;
        }
      }

      a.active {
        background: rgba(0, 0, 0, 0.08);
        div {
          background: var(--secondary);
        }

        &::before {
          background: var(--secondary);
        }
      }
    }

    li:last-child {
      a {
        border-bottom: none;
      }
    }
  }
}
