@import '../../../assets/sass/variables';

.eventMonthView {
  .eventInfo {
    cursor: pointer;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    border-radius: 5px;
    height: 18px;

    .eventBullet {
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .eventTitle {
      font-weight: 700;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.eventFullDay {
      background-color: var(--primary);
      color: #fff;
      font-weight: 700;
    }

    &:hover:not(.eventFullDay) {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.eventWeekView {
  .eventInfo {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 5px;
    margin-bottom: 3px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .eventTitle {
      font-weight: 600;
    }

    .eventTime {
      font-size: 12px;
    }

    &.eventFullDay {
      padding: 4px 5px;
      font-weight: 600;
    }
  }
}
