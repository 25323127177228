@import "variables";
@import "functions";

:root {
  --primary: #274287;
  --primaryHover: #{darken(#274287, 15%)};
  --secondary: #f08b64;
  --secondaryHover: #{lighten(#f08b64, 5%)};
}

hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.badge-blue {
  color: #fff;
  background-color: #4089ff;
}

.card {
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 00, 0.06);
  border: 0;

  .card-header {
    background: transparent;
    // border-bottom: 0px;
    padding: remSize(20px) 0px;
    margin-left: remSize(30px);
    margin-right: remSize(30px);
    border-bottom: 2px solid #EEEEEE;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0px;
    }

    &.no-border {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .card-body {
    padding: remSize(30px);
  }

  .card-footer {
    background: transparent;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 30px;
    margin-right: 30px;
    border-top: 2px solid #EEEEEE;
  }
}

.form-control {
  border-radius: 5px;
}

input.form-control,
textarea.form-control,
select.custom-select,
select.form-control {
  background-color: rgba(0, 0, 0, 0.015);
  //box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
}

input.form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  outline: none;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-color: rgba(0, 0, 0, 0.3);
}

label {
  font-weight: bold;
  color: var(--secondary);
}

.custom-radio label {
  color: $text-color;
  font-weight: normal;
}

.btn {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  font-size: remSize(14px);
  border-radius: remSize(15px);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-sm {
  font-size: remSize(12px);
  padding: 0.15rem 1.2rem;
}

.btn-default {
  color: $text-color;
  font-weight: 500;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    color: darken($text-color, 10%);
  }
}

.btn-primary {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);

  &:disabled {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    color: white;
    outline: 0;
    background-color: var(--primaryHover);
    border-color: var(--primaryHover);
    box-shadow: none;
  }
}

.btn-secondary {
  color: white;
  background-color: var(--secondary);
  border-color: var(--secondary);

  &:disabled {
    background-color: var(--secondary);
    border-color: var(--secondary);
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    outline: 0;
    background-color: var(--secondaryHover);
    border-color: var(--secondaryHover);
    box-shadow: none;
  }
}

.badge-primary {
  color: white;
  background-color: var(--primary);
}
.badge-secondary {
  color: white;
  background-color: var(--secondary);
}

.modal {
  z-index: 100;

  &.modal-visible {
    background: rgba(0, 0, 0, 0.25);
    display: block;
  }

  .modal-dialog {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
    max-height: 95vh;
    overflow: hidden;
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    max-height: 95vh;
  }

  .modal-header {
    color: white;
    font-size: remSize(18px);
    padding: remSize(30px) remSize(40px);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    background: linear-gradient(to right, var(--primary), var(--secondary));

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: inherit;
      font-size: inherit;
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: remSize(30px) remSize(40px);
    overflow: auto;
  }

  .modal-footer {
    padding: remSize(25px) remSize(10px);
    margin: 0 remSize(25px);
    border-top: 2px solid #EEEEEE;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
}

.table-striped tbody td {
  border-top: 2px solid #EEEEEE;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,0.015);
}

.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.05);
}

.table thead th {
  border-bottom: 4px solid var(--primary);
}

.pagination {
  .page-item {
    position: relative;
    padding: 0 5px;
    &:not(.control){
      border-left: 1px dotted var(--secondary);
    }
    &.k0{
      border-left: none;
    }

    &.disabled {
      opacity: .3;
    }

    .page-link{
      color: var(--secondary);
      border-radius: 4px;
      padding: remSize(3px) remSize(4px);
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      font-size: remSize(14px);
      border: 1px solid transparent;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        background: white;
        color: $text-color;
        border-color: $text-color;
      }
    }

    &.control {
      .page-link {
        background-color: var(--secondary);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        text-align: center;
        border: none;
        color: white;
        &:hover{
          background-color: var(--secondaryHover);
        }
      }

      &:first-child,
      &:last-child {
        .page-link {
          opacity: .75;
        }
      }
    }
  }
}

.dropdown-menu {
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  border: none;
  border-top: 1px solid rgba(0,0,0,0.1);

  .dropdown-item {
    color: $text-color;

    &:active{
      background-color: darken(white, 10%);
    }
  }
}
