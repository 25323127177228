@import '../../../assets/sass/functions';
@import '../../../assets/sass/variables';

.table {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

  &.table-lg {
    tbody td {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  thead th,
  thead th a.sort-label {
    border-top: 0;
    font-weight: 400;
    color: var(--primary);
    font-size: remSize(12px);
  }

  tbody td {
    font-size: remSize(12px);
    color: $text-color;
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 400;
  }
}
