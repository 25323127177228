@import '../../../../assets/sass/functions';

.mainContainer {
  margin: 0 auto;
  max-width: 360px;
}

.schoolName {
  font-size: remSize(22px);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  margin-bottom: remSize(16px);
}
