@import '../../../../assets/sass/functions';
@import '../../../../assets/sass/variables';

.monthView {
  .weekdaysContainer {
    color: $primary;
    display: flex;
    border-bottom: 4px solid $secondary;
    padding: 5px;
    text-align: center;
    margin-bottom: 14px;

    .weekday {
      width: (100% / 7);
      font-size: remSize(10px);
      font-weight: 700;
    }
  }

  .cellsContainer {
    display: flex;
    flex-wrap: wrap;

    .cell,
    .emptyCell {
      position: relative;
      width: (100% / 7);
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      text-align: center;
      height: 150px;
      margin-bottom: -1px;
      padding-top: 5px;

      &:nth-child(7n) {
        border-right: 0;
      }

      .today {
        color: $primary;
        display: inline-block;
        padding: 0px 7px;
        border-bottom: 2px solid $primary;
      }

      .events {
        margin: 5px;
        font-size: 12px;
      }
    }

    .dayNumber {
      position: absolute;
      bottom: remSize(10px);
      right: remSize(10px);
      font-weight: 700;
      font-size: remSize(21px);
      color: lighten($text-color, 20%)
    }

    .dayNumber.active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }
}
